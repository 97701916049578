import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { NotFoundPage } from 'pages/errors/NotFoundPage';
import { FC } from 'react';
import { Outlet } from 'react-router';

export const HouseLayout: FC = () => {
  const { selectedHouse } = useSelectedHouse();

  if (selectedHouse === null) {
    return <NotFoundPage />;
  }

  return <Outlet />;
};
