import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useUserHouses } from 'context/UserHousesContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useHousePickerStore } from 'stores/housePickerStore';

export const useSelectedHouse = () => {
  const { houseId: houseIdFromParams } = useParams<{ houseId: string }>();
  const { api } = useApiContext();
  const { houses } = useUserHouses();
  const { authedUser } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  // Local store for the selected house id
  const { selectedHouseId, setSelectedHouseId } = useHousePickerStore();

  // Query for the selected house
  const selectedHouseQuery = useQuery({
    queryKey: [
      QueryKeys.GET_USER_HOUSE,
      { userId: authedUser?.uid, houseId: selectedHouseId },
    ],
    queryFn: selectedHouseId
      ? () =>
          api.users.getUserHouse({
            houseId: selectedHouseId,
          })
      : skipToken,
  });

  // Navigate to the current url with the target houseId (if applies) or update the selected house id
  const setSelectedHouseHandler = useCallback(
    (houseId: string) => {
      const hasCurrentSelectedHouseIdInUrl =
        selectedHouseId &&
        new RegExp(`/houses/${selectedHouseId}`).test(location.pathname);

      if (hasCurrentSelectedHouseIdInUrl) {
        const newUrl = location.pathname.replace(selectedHouseId, houseId);
        navigate(newUrl, { replace: true });
      } else {
        setSelectedHouseId(houseId);
      }
    },
    [location.pathname, navigate, selectedHouseId, setSelectedHouseId],
  );

  // Resolve the selected house
  useEffect(() => {
    // If houses is resolved and there are no houses, clear the selected house id
    if (typeof houses !== 'undefined' && houses.length === 0) {
      setSelectedHouseId(undefined);
      return;
    }

    // If houseId is in the url, use it
    if (houseIdFromParams) {
      setSelectedHouseId(houseIdFromParams);
      return;
    }

    // If there is no houseId in the url, and not selected house id, and there are houses, use the first house id
    if (!houseIdFromParams && !selectedHouseId && houses && houses.length > 0) {
      setSelectedHouseHandler(houses[0].details.houseId);
      return;
    }
  }, [
    houseIdFromParams,
    houses,
    selectedHouseId,
    selectedHouseQuery.data?.details.houseId,
    setSelectedHouseHandler,
    setSelectedHouseId,
  ]);

  // Calculate the date limits for the selected house
  const selectedHouseDateLimits = useMemo(() => {
    const minStartTimestamp = Math.min(
      selectedHouseQuery.data?.houseMetadata.firstDateEnergy?.getTime() ||
        Infinity,
      selectedHouseQuery.data?.houseMetadata.firstDatePower?.getTime() ||
        Infinity,
    );

    const maxEndTimestamp = Math.max(
      selectedHouseQuery.data?.houseMetadata.lastDateEnergy?.getTime() ||
        -Infinity,
      selectedHouseQuery.data?.houseMetadata.lastDatePower?.getTime() ||
        -Infinity,
    );

    const minStartDate = Number.isFinite(minStartTimestamp)
      ? new Date(minStartTimestamp)
      : undefined;
    const maxEndDate = Number.isFinite(maxEndTimestamp)
      ? new Date(maxEndTimestamp)
      : undefined;

    return {
      minStartDate,
      maxEndDate,
    };
  }, [
    selectedHouseQuery.data?.houseMetadata.firstDateEnergy,
    selectedHouseQuery.data?.houseMetadata.firstDatePower,
    selectedHouseQuery.data?.houseMetadata.lastDateEnergy,
    selectedHouseQuery.data?.houseMetadata.lastDatePower,
  ]);

  return {
    selectedHouse: !selectedHouseQuery.isError ? selectedHouseQuery.data : null,
    selectedHouseDateLimits,
    setSelectedHouse: setSelectedHouseHandler,
  };
};
