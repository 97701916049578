import { CreateTicketRequest } from '@clevergy/api-client';
import { Button } from '@clevergy/ui/components/Button';
import { InputSelect } from '@clevergy/ui/components/InputSelect';
import { InputText } from '@clevergy/ui/components/InputText';
import { InputTextArea } from '@clevergy/ui/components/InputTextArea';
import { useMutation } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useUserHouses } from 'context/UserHousesContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

type SupportTicketCreateFormSchema = {
  houseId?: string;
  title: string;
  description: string;
};

export const SupportTicketCreatePage: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { houses } = useUserHouses();
  const { selectedHouse } = useSelectedHouse();

  const [searchParams] = useSearchParams();
  const subject = searchParams.get('subject') || 'Otras consultas';

  const housesList = useMemo(() => {
    if (!houses) {
      return null;
    }
    return houses.map((house) => ({
      id: house.details.houseId,
      text: house.details.address || 'Sin dirección',
    }));
  }, [houses]);

  const createIssueMutation = useMutation({
    mutationFn: (params: SupportTicketCreateFormSchema) => {
      const { houseId, title, description } = params;

      if (!authedUser || !authedUser.tenantId) {
        throw new Error('User not authenticated');
      }

      const newTicket: CreateTicketRequest = {
        title,
        description,
        tenantId: authedUser.tenantId,
      };

      if (houseId) {
        newTicket.houseId = houseId;
      }

      return api.support.createTicket({
        userId: authedUser.uid || '',
        createTicketRequest: newTicket,
      });
    },
    onSuccess: () => {
      onFinish();
    },
  });

  const { register, handleSubmit, formState, clearErrors, setValue, watch } =
    useForm<SupportTicketCreateFormSchema>({
      defaultValues: {
        title: subject,
        houseId: selectedHouse?.details.houseId,
      },
    });

  const selectedHouseId = watch('houseId');

  const handleSubmitCreateTicketForm = (data: SupportTicketCreateFormSchema) =>
    createIssueMutation.mutate(data);

  const onFinish = () => {
    navigate('/support/tickets/success');
  };

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath="/support"
        title={t('support.createTicket.title') as string}
      />

      <Wrapper>
        <form
          onSubmit={handleSubmit(handleSubmitCreateTicketForm)}
          className="flex w-full flex-col gap-4 justify-center"
        >
          {housesList && housesList.length > 0 && (
            <InputSelect
              id="houseId"
              values={housesList}
              selectedValueId={
                selectedHouseId || selectedHouse?.details.houseId
              }
              label={t('support.createTicket.form.house.label')}
              placeholder={t('support.createTicket.form.house.placeholder')}
              fullWidth
              {...register('houseId')}
              onChange={(e) => {
                setValue('houseId', e.target.value);
                clearErrors();
              }}
            />
          )}

          <InputText
            id="title"
            type="text"
            label={t('support.createTicket.form.title.label') as string}
            placeholder={t('support.createTicket.form.title.placeholder')}
            error={formState.errors.title?.message}
            {...register('title', {
              required: t('support.createTicket.form.title.required') as string,
            })}
          />

          <InputTextArea
            id="description"
            label="Descripción"
            rows={6}
            autoComplete="off"
            placeholder={t('support.createTicket.form.description.placeholder')}
            error={formState.errors.description?.message}
            {...register('description', {
              required: t(
                'support.createTicket.form.description.required',
              ) as string,
            })}
          />

          <Button
            fullWidth
            type="submit"
            variant="filled"
            disabled={
              formState.isSubmitting ||
              !formState.isValid ||
              createIssueMutation.isPending
            }
            busy={formState.isSubmitting || createIssueMutation.isPending}
            onClick={() => clearErrors()}
          >
            {t('support.createTicket.form.actions.submit')}
          </Button>
        </form>
      </Wrapper>
    </Page>
  );
};
