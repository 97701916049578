import SurplusIllustration from '../assets/surplus-illustration.svg?react';
import { AerothermalModal } from './AerothermalModal';
import { BatteryModal } from './BatteryModal';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { Carousel } from '@clevergy/ui/components/Carousel';
import { Icon } from '@clevergy/ui/components/Icon';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { formatNumber } from '@clevergy/utils/format/formatNumber';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { format, startOfDay, subDays, subMonths } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const SurplusRecommendations: FC = () => {
  const { api } = useApiContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { selectedHouse } = useSelectedHouse();
  const [showAerothermalModal, setShowAerothermalModal] = useState(false);
  const [showBatteryModal, setShowBatteryModal] = useState(false);

  const hasSolar = Boolean(selectedHouse?.houseMetadata.lastDatePower);
  const timeZone = selectedHouse?.details.timeZone || 'Etc/UTC';

  const endDateZoned = subDays(new Date(), 1);
  const startDateZoned = startOfDay(subMonths(endDateZoned, 1));
  const endDateUTC = fromZonedTime(endDateZoned, timeZone);
  const startDateUTC = fromZonedTime(startDateZoned, timeZone);

  const solarInfoQuery = useQuery({
    queryKey: [
      QueryKeys.SOLAR_INFO,
      {
        houseId: selectedHouse?.details.houseId,
        startDate: format(startDateZoned, 'd MMM yyyy'),
        endDate: format(endDateZoned, 'd MMM yyyy'),
      },
    ],
    queryFn:
      hasSolar && selectedHouse?.details.houseId
        ? () =>
            api.solar.getSolarInfo({
              houseId: selectedHouse.details.houseId,
              startDate: startDateUTC,
              endDate: endDateUTC,
            })
        : skipToken,
  });

  const getHouseSettingsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_HOUSE_SETTINGS,
      { houseId: selectedHouse?.details.houseId },
    ],
    queryFn:
      hasSolar && selectedHouse?.details.houseId
        ? () =>
            api.settings.getHouseSettings({
              houseId: selectedHouse.details.houseId,
            })
        : skipToken,
  });

  const hasSurplus =
    (solarInfoQuery.data?.exportOverProductionPercentage || 0) >= 20;

  const hasBattery = getHouseSettingsQuery.data?.devices?.includes('BATTERY');
  const hasAerothermal =
    getHouseSettingsQuery.data?.devices?.includes('AEROTHERMAL');

  if (!hasSolar || !hasSurplus || (hasBattery && hasAerothermal)) {
    return null;
  }

  const exportOverProductionPercentage = formatNumber({
    value: solarInfoQuery.data?.exportOverProductionPercentage || 0,
    language,
    precision: 0,
  }).formattedValue;

  return (
    <ModuleCard>
      <ModuleCard.Header title={t('optimize.recommendations.banner.title')} />
      <ModuleCard.Content>
        <div className="flex flex-col gap-2 items-center mb-4">
          <SurplusIllustration />
          <p>
            <Trans
              i18nKey="optimize.recommendations.banner.message"
              values={{ percentage: exportOverProductionPercentage }}
            />
          </p>
        </div>

        {(!hasBattery || !hasAerothermal) && (
          <Carousel transitionSeconds={0.5}>
            {!hasBattery ? (
              <Carousel.Item
                icon={
                  <span className="text-clevergy-primary">
                    <Icon name="DeviceBattery" inline />
                  </span>
                }
                title={t('optimize.recommendations.tips.battery.title')}
              >
                <p>
                  {t('optimize.recommendations.tips.battery.message')}{' '}
                  <button
                    type="button"
                    onClick={() => setShowBatteryModal(true)}
                    className="text-clevergy-primary font-semibold underline"
                  >
                    {t('optimize.recommendations.tips.battery.cta')}
                  </button>
                </p>
              </Carousel.Item>
            ) : (
              <></>
            )}
            {!hasAerothermal ? (
              <Carousel.Item
                icon={
                  <span className="text-clevergy-primary">
                    <Icon name="DeviceAerothermal" inline />
                  </span>
                }
                title={t('optimize.recommendations.tips.aerothermal.title')}
              >
                <p>
                  {t('optimize.recommendations.tips.aerothermal.message')}{' '}
                  <button
                    type="button"
                    onClick={() => setShowAerothermalModal(true)}
                    className="text-clevergy-primary font-semibold underline"
                  >
                    {t('optimize.recommendations.tips.aerothermal.cta')}
                  </button>
                </p>
              </Carousel.Item>
            ) : (
              <></>
            )}
          </Carousel>
        )}
      </ModuleCard.Content>

      <BatteryModal
        isOpen={showBatteryModal}
        onClose={() => setShowBatteryModal(false)}
      />

      <AerothermalModal
        isOpen={showAerothermalModal}
        onClose={() => setShowAerothermalModal(false)}
      />
    </ModuleCard>
  );
};
