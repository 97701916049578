import { UserHouse } from '@clevergy/api-client';

export const getShellyIntegrationConnectUrl = (house: UserHouse) => {
  const shellyIntegratorUrl = 'https://my.shelly.cloud/integrator.html';
  const shellyCallbackUrl = `https://api.clever.gy/houses/${house.details.houseId}/integrations/shelly`;

  const shellyCustomField = 'clevergy';
  const shellyIntegratorParams = new URLSearchParams({
    itg: 'ITG_CVG',
    cb: shellyCallbackUrl,
    customField: shellyCustomField,
  });

  return `${shellyIntegratorUrl}?${shellyIntegratorParams.toString()}`;
};
