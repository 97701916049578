import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { InputCheck } from '@clevergy/ui/components/InputCheck';
import { InputText } from '@clevergy/ui/components/InputText';
import { Modal } from '@clevergy/ui/components/Modal';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

type AddAuthorizedUserFormValues = {
  email: string;
  consentDataUsage: boolean;
  customError?: string;
};

export type AddAuthorizedUserModalProps = {
  onCancel: () => void;
  onAccessAdded: () => void;
  houseId: string;
  houseName?: string;
};

export const AddAuthorizedUserModal: FC<AddAuthorizedUserModalProps> = ({
  onCancel,
  onAccessAdded,
  houseId,
  houseName,
}) => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const [hasConsentChecked, setHasConsentChecked] = useState<boolean | null>(
    null,
  );

  const userId = authedUser?.uid;

  const { register, handleSubmit, formState, setError, clearErrors } =
    useForm<AddAuthorizedUserFormValues>({
      defaultValues: {
        consentDataUsage: false,
      },
    });

  const addAuthorizedUserMutation = useMutation({
    mutationFn: (userAuthorizedEmail: string) => {
      if (!userId) {
        throw new Error('User ID is required');
      }
      return api.users.authorizeUser({
        userId,
        authorizeUserRequest: {
          houseId,
          userAuthorizedEmail,
        },
      });
    },
    onSuccess: () => {
      onAccessAdded();
    },
    onError: () => {
      setError('customError', {
        message: t('sharedAccess.addAuthorizedUserModal.form.error'),
      });
    },
  });

  const onSubmit = async (data: AddAuthorizedUserFormValues) => {
    const { email, consentDataUsage } = data;
    if (!consentDataUsage) {
      setError('consentDataUsage', {
        message: t(
          'sharedAccess.addAuthorizedUserModal.form.acceptDataConsent.required',
        ),
      });
      return;
    }
    await addAuthorizedUserMutation.mutateAsync(email);
  };

  return (
    <Modal isOpen={true} onClose={() => onCancel()}>
      <form
        className="h-full flex flex-col gap-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col justify-center gap-4">
          <p className="text-2xl font-semibold">
            {t('sharedAccess.addAuthorizedUserModal.title')}
          </p>
          <p>
            <Trans
              i18nKey="sharedAccess.addAuthorizedUserModal.intro"
              values={{ houseName: houseName || 'Sin dirección' }}
              shouldUnescape
            />
          </p>
          <InputText
            id="name"
            type="text"
            label={t('sharedAccess.addAuthorizedUserModal.form.email.label')}
            placeholder={t(
              'sharedAccess.addAuthorizedUserModal.form.email.placeholder',
            )}
            autoComplete="off"
            error={formState.errors.email?.message as string}
            {...register('email', {
              required: t(
                'sharedAccess.addAuthorizedUserModal.form.email.required',
              ),
              validate: (value) => {
                if (!isValidEmail(value)) {
                  return t(
                    'sharedAccess.addAuthorizedUserModal.form.email.invalid',
                  );
                }
              },
            })}
          />
          <p>{t('sharedAccess.addAuthorizedUserModal.form.disclaimer')}</p>

          <InputCheck
            id="consentDataUsage"
            label={t(
              'sharedAccess.addAuthorizedUserModal.form.acceptDataConsent.label',
            )}
            isChecked={Boolean(hasConsentChecked)}
            error={formState.errors.consentDataUsage?.message}
            {...register('consentDataUsage', {
              required: t(
                'sharedAccess.addAuthorizedUserModal.form.acceptDataConsent.required',
              ) as string,
              onChange: (e) => setHasConsentChecked(e.target.checked),
            })}
          />

          {formState.errors.customError && (
            <Alert
              type="error"
              text={formState.errors.customError?.message as string}
            />
          )}
        </div>
        <div className="grow"></div>
        <div className="flex flex-col gap-2 w-full">
          <Button variant="flat" fullWidth onClick={onCancel}>
            {t('sharedAccess.addAuthorizedUserModal.form.cancelButton')}
          </Button>
          <Button
            fullWidth
            variant="filled"
            type="submit"
            busy={addAuthorizedUserMutation.isPending}
            disabled={addAuthorizedUserMutation.isPending}
            onClick={() => {
              clearErrors();
            }}
          >
            {t('sharedAccess.addAuthorizedUserModal.form.submitButton')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
