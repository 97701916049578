import { DateRangeType } from '@clevergy/shared/types';
import { AnalysisDatePicker } from 'components/AnalysisDatePicker';
import { AppAlerts } from 'components/AppAlerts';
import { DraftModuleBreakdown } from 'components/DraftModuleBreakdown';
import { DraftModuleEnergyChart } from 'components/DraftModuleEnergyChart';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { SideBarContainer } from 'components/SideBarContainer';
import { SmartMeterBanner } from 'components/SmartMeterBanner';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserInfo } from 'context/UserInfoContext';
import { differenceInDays, format } from 'date-fns';
import { useAnalysisPageFilters } from 'hooks/useAnalysisPageFilters';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { dateFnsLocales } from 'i18n';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const AnalysisPage: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const locale = dateFnsLocales[language];

  const { token } = useAuthContext();
  const { tenantId } = useTenantSettings();
  const { userInfo } = useUserInfo();
  const { selectedHouse, selectedHouseDateLimits } = useSelectedHouse();

  const {
    dateRangeType,
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
  } = useAnalysisPageFilters();

  const formattedStartDate = customStartDate
    ? format(customStartDate, 'yyyy-MM-dd')
    : undefined;
  const formattedEndDate = customEndDate
    ? format(customEndDate, 'yyyy-MM-dd')
    : undefined;
  const formattedRangeCurrentDate = dateRangeCurrentDate
    ? format(dateRangeCurrentDate, 'yyyy-MM-dd')
    : undefined;

  const shouldReplaceEnergyWithPower =
    selectedHouse?.houseMetadata.lastDatePower &&
    (dateRangeType === DateRangeType.Day ||
      (dateRangeType === DateRangeType.Custom &&
        customStartDate &&
        customEndDate &&
        Math.abs(differenceInDays(customStartDate, customEndDate)) === 0));

  const shouldHideSolarSummary =
    dateRangeCurrentDate &&
    dateRangeType === DateRangeType.Day &&
    differenceInDays(dateRangeCurrentDate, new Date()) === 0;

  const hasDownloadConsumptionsCsvEnabled = useMemo(() => {
    return tenantId === 'grinch';
  }, [tenantId]);

  return (
    <Page>
      <PageHeader withHousePicker>
        {selectedHouseDateLimits.minStartDate &&
          selectedHouseDateLimits.maxEndDate && (
            <AnalysisDatePicker
              texts={{
                day: t('analysis.day'),
                month: t('analysis.month'),
                year: t('analysis.year'),
                custom: t('analysis.custom'),
                cancelButton: t('analysis.cancelButton'),
                okButton: t('analysis.okButton'),
              }}
              locale={locale}
            />
          )}
      </PageHeader>
      <PullToRefresh />

      {/* sidebar */}
      <div className="relative">
        <SideBarContainer>
          <></>
        </SideBarContainer>
      </div>

      <Wrapper>
        {!token && <TenantLoadingIndicator />}
        {token && (
          <>
            <AppAlerts />

            <SmartMeterBanner />

            {selectedHouse && !shouldHideSolarSummary && (
              <clevergy-solar-summary
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-show-dates-selector="false"
                data-date-range-type={dateRangeType}
                data-date-range-current-date={formattedRangeCurrentDate}
                data-custom-start-date={formattedStartDate}
                data-custom-end-date={formattedEndDate}
              />
            )}

            {selectedHouse && (
              <>
                {shouldReplaceEnergyWithPower ? (
                  <clevergy-power-chart
                    data-title={t('analysis.solarDailyPowerChartTitle')}
                    data-token={token}
                    data-house-id={selectedHouse.details.houseId}
                    data-current-date={
                      formattedStartDate || formattedRangeCurrentDate
                    }
                  />
                ) : (
                  <clevergy-energy-chart
                    data-house-id={selectedHouse.details.houseId}
                    data-token={token}
                    data-show-dates-selector="false"
                    data-date-range-type={dateRangeType}
                    data-date-range-current-date={formattedRangeCurrentDate}
                    data-custom-start-date={formattedStartDate}
                    data-custom-end-date={formattedEndDate}
                    data-show-average-consumption="false"
                    data-show-download-csv={hasDownloadConsumptionsCsvEnabled}
                  ></clevergy-energy-chart>
                )}
              </>
            )}

            {selectedHouse && !userInfo.isCompany && (
              <clevergy-breakdown
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-show-dates-selector="false"
                data-date-range-type={dateRangeType}
                data-date-range-current-date={formattedRangeCurrentDate}
                data-custom-start-date={formattedStartDate}
                data-custom-end-date={formattedEndDate}
              ></clevergy-breakdown>
            )}

            {/* Draft modules */}
            <DraftModuleEnergyChart />
            <DraftModuleBreakdown />
          </>
        )}
      </Wrapper>
    </Page>
  );
};
