/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  AnalyzedInvoice,
  AppUserSetUp,
  AuthorizeUserChangeStatusRequest,
  AuthorizeUserRequest,
  AuthorizedUser,
  ContractLead,
  CreateLead,
  DataConsent,
  HttpError,
  InvoiceAnalysis,
  UpdateContractLead,
  UploadDniUrl,
  UploadInvoiceUrl,
  UserAccountDetails,
  UserAppDevice,
  UserHousePage,
  UserHouseWithIntegrations,
  UserIntegrationsResponse,
  UserPreferencesNotificationRequest,
  UserPreferencesNotificationResponse,
  UserRegistration,
  UserSetUp,
} from '../models/index';
import {
  AnalyzedInvoiceFromJSON,
  AnalyzedInvoiceToJSON,
  AppUserSetUpFromJSON,
  AppUserSetUpToJSON,
  AuthorizeUserChangeStatusRequestFromJSON,
  AuthorizeUserChangeStatusRequestToJSON,
  AuthorizeUserRequestFromJSON,
  AuthorizeUserRequestToJSON,
  AuthorizedUserFromJSON,
  AuthorizedUserToJSON,
  ContractLeadFromJSON,
  ContractLeadToJSON,
  CreateLeadFromJSON,
  CreateLeadToJSON,
  DataConsentFromJSON,
  DataConsentToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  InvoiceAnalysisFromJSON,
  InvoiceAnalysisToJSON,
  UpdateContractLeadFromJSON,
  UpdateContractLeadToJSON,
  UploadDniUrlFromJSON,
  UploadDniUrlToJSON,
  UploadInvoiceUrlFromJSON,
  UploadInvoiceUrlToJSON,
  UserAccountDetailsFromJSON,
  UserAccountDetailsToJSON,
  UserAppDeviceFromJSON,
  UserAppDeviceToJSON,
  UserHousePageFromJSON,
  UserHousePageToJSON,
  UserHouseWithIntegrationsFromJSON,
  UserHouseWithIntegrationsToJSON,
  UserIntegrationsResponseFromJSON,
  UserIntegrationsResponseToJSON,
  UserPreferencesNotificationRequestFromJSON,
  UserPreferencesNotificationRequestToJSON,
  UserPreferencesNotificationResponseFromJSON,
  UserPreferencesNotificationResponseToJSON,
  UserRegistrationFromJSON,
  UserRegistrationToJSON,
  UserSetUpFromJSON,
  UserSetUpToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface AcceptDataConsentRequest {
  userId: string;
  dataConsent?: DataConsent;
}

export interface AnalyzeInvoiceRequest {
  userId: string;
}

export interface AuthorizeUserOperationRequest {
  userId: string;
  authorizeUserRequest?: AuthorizeUserRequest;
}

export interface ChangeStatusForUserAuthorizationRequest {
  userId: string;
  changeAuthorizationUserRequest?: AuthorizeUserChangeStatusRequest;
}

export interface CreateDeleteUserAccountRequestRequest {
  userId: string;
}

export interface CreateLeadRequest {
  userId: string;
  createLead?: CreateLead;
}

export interface CreateUserAppDeviceRequest {
  userId: string;
  userAppDevice?: UserAppDevice;
}

export interface DenyDataConsentRequest {
  userId: string;
}

export interface GetAppUserSetUpRequest {
  userId: string;
}

export interface GetInvoiceAnalysisByIdRequest {
  userId: string;
  invoiceAnalysisId: string;
}

export interface GetOpenContractLeadRequest {
  userId: string;
}

export interface GetPreferencesNotificationsRequest {
  userId: string;
  type: GetPreferencesNotificationsTypeEnum;
}

export interface GetUploadDniSignedUrlRequest {
  userId: string;
}

export interface GetUploadInvoiceSignedUrlRequest {
  userId: string;
}

export interface GetUserAccountDetailsRequest {
  userId: string;
}

export interface GetUserHouseRequest {
  houseId: string;
}

export interface GetUserHousesRequest {
  userId: string;
  page: number;
  size: number;
  cups?: string;
  sort?: string;
  direction?: string;
}

export interface GetUserIntegrationsRequest {
  userId: string;
}

export interface GetUsersAuthorizedByUserRequest {
  userId: string;
}

export interface MarkAsValidatingHolderRequest {
  userId: string;
}

export interface MarkAsWaitingAuthorizationRequest {
  userId: string;
}

export interface MarkUserAsCompletedRequest {
  userId: string;
}

export interface RegisterUserRequest {
  user?: UserRegistration;
}

export interface RetrieveUserSetUpRequest {
  userId: string;
}

export interface SubmitOpenContractLeadRequest {
  userId: string;
}

export interface TrackUserAppLoginRequest {
  userId: string;
}

export interface UpdateOpenContractLeadRequest {
  userId: string;
  updateContractLead?: UpdateContractLead;
}

export interface UpdatePreferencesNotificationsRequest {
  userId: string;
  type: UpdatePreferencesNotificationsTypeEnum;
  updateUserPreferencesNotifications?: UserPreferencesNotificationRequest;
}

export interface UpdateUserAccountDetailsRequest {
  userId: string;
  details?: UserAccountDetails;
}

export interface ValidateDniRequest {
  userId: string;
}

export interface ValidateInvoiceRequest {
  userId: string;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * accepts data consent
   */
  async acceptDataConsentRaw(
    requestParameters: AcceptDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling acceptDataConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/data-consent/accept`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DataConsentToJSON(requestParameters['dataConsent']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * accepts data consent
   */
  async acceptDataConsent(
    requestParameters: AcceptDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.acceptDataConsentRaw(requestParameters, initOverrides);
  }

  /**
   * Executes an analysis on latest uploaded invoice.
   */
  async analyzeInvoiceRaw(
    requestParameters: AnalyzeInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AnalyzedInvoice>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling analyzeInvoice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/invoice-analysis`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AnalyzedInvoiceFromJSON(jsonValue),
    );
  }

  /**
   * Executes an analysis on latest uploaded invoice.
   */
  async analyzeInvoice(
    requestParameters: AnalyzeInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AnalyzedInvoice> {
    const response = await this.analyzeInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Authorizes a user resource (a house) to another user
   */
  async authorizeUserRaw(
    requestParameters: AuthorizeUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling authorizeUser().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/authorize-user`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthorizeUserRequestToJSON(
          requestParameters['authorizeUserRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Authorizes a user resource (a house) to another user
   */
  async authorizeUser(
    requestParameters: AuthorizeUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.authorizeUserRaw(requestParameters, initOverrides);
  }

  /**
   * Change the authorization status of a user
   */
  async changeStatusForUserAuthorizationRaw(
    requestParameters: ChangeStatusForUserAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling changeStatusForUserAuthorization().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/change-authorization-status`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthorizeUserChangeStatusRequestToJSON(
          requestParameters['changeAuthorizationUserRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Change the authorization status of a user
   */
  async changeStatusForUserAuthorization(
    requestParameters: ChangeStatusForUserAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.changeStatusForUserAuthorizationRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Creates a request to delete user account
   */
  async createDeleteUserAccountRequestRaw(
    requestParameters: CreateDeleteUserAccountRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling createDeleteUserAccountRequest().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/delete-account`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a request to delete user account
   */
  async createDeleteUserAccountRequest(
    requestParameters: CreateDeleteUserAccountRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createDeleteUserAccountRequestRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Creates a lead for a specific user
   */
  async createLeadRaw(
    requestParameters: CreateLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling createLead().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/leads`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateLeadToJSON(requestParameters['createLead']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a lead for a specific user
   */
  async createLead(
    requestParameters: CreateLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createLeadRaw(requestParameters, initOverrides);
  }

  /**
   * Registers an user application device
   */
  async createUserAppDeviceRaw(
    requestParameters: CreateUserAppDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling createUserAppDevice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/app-devices`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserAppDeviceToJSON(requestParameters['userAppDevice']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Registers an user application device
   */
  async createUserAppDevice(
    requestParameters: CreateUserAppDeviceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createUserAppDeviceRaw(requestParameters, initOverrides);
  }

  /**
   * denies data consent
   */
  async denyDataConsentRaw(
    requestParameters: DenyDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling denyDataConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/data-consent/deny`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * denies data consent
   */
  async denyDataConsent(
    requestParameters: DenyDataConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.denyDataConsentRaw(requestParameters, initOverrides);
  }

  /**
   * Returns the user setup.
   */
  async getAppUserSetUpRaw(
    requestParameters: GetAppUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppUserSetUp>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getAppUserSetUp().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/users/{userId}/setup`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppUserSetUpFromJSON(jsonValue),
    );
  }

  /**
   * Returns the user setup.
   */
  async getAppUserSetUp(
    requestParameters: GetAppUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppUserSetUp> {
    const response = await this.getAppUserSetUpRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get latest available invoice analysis
   */
  async getInvoiceAnalysisByIdRaw(
    requestParameters: GetInvoiceAnalysisByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InvoiceAnalysis>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getInvoiceAnalysisById().',
      );
    }

    if (requestParameters['invoiceAnalysisId'] == null) {
      throw new runtime.RequiredError(
        'invoiceAnalysisId',
        'Required parameter "invoiceAnalysisId" was null or undefined when calling getInvoiceAnalysisById().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/invoice-analysis/{invoiceAnalysisId}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters['userId'])),
          )
          .replace(
            `{${'invoiceAnalysisId'}}`,
            encodeURIComponent(String(requestParameters['invoiceAnalysisId'])),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InvoiceAnalysisFromJSON(jsonValue),
    );
  }

  /**
   * Get latest available invoice analysis
   */
  async getInvoiceAnalysisById(
    requestParameters: GetInvoiceAnalysisByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InvoiceAnalysis> {
    const response = await this.getInvoiceAnalysisByIdRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * gets open contract lead
   */
  async getOpenContractLeadRaw(
    requestParameters: GetOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ContractLead>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getOpenContractLead().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/open-contract-lead`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ContractLeadFromJSON(jsonValue),
    );
  }

  /**
   * gets open contract lead
   */
  async getOpenContractLead(
    requestParameters: GetOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ContractLead> {
    const response = await this.getOpenContractLeadRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get user preferences notifications
   */
  async getPreferencesNotificationsRaw(
    requestParameters: GetPreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserPreferencesNotificationResponse>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getPreferencesNotifications().',
      );
    }

    if (requestParameters['type'] == null) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter "type" was null or undefined when calling getPreferencesNotifications().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/notifications/{type}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters['userId'])),
          )
          .replace(
            `{${'type'}}`,
            encodeURIComponent(String(requestParameters['type'])),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserPreferencesNotificationResponseFromJSON(jsonValue),
    );
  }

  /**
   * Get user preferences notifications
   */
  async getPreferencesNotifications(
    requestParameters: GetPreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserPreferencesNotificationResponse> {
    const response = await this.getPreferencesNotificationsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns a signed, temporary bucket url to upload a dni image
   */
  async getUploadDniSignedUrlRaw(
    requestParameters: GetUploadDniSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadDniUrl>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUploadDniSignedUrl().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/upload-dni-url`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadDniUrlFromJSON(jsonValue),
    );
  }

  /**
   * Returns a signed, temporary bucket url to upload a dni image
   */
  async getUploadDniSignedUrl(
    requestParameters: GetUploadDniSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadDniUrl> {
    const response = await this.getUploadDniSignedUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns a signed, temporary bucket url to upload an invoice file
   */
  async getUploadInvoiceSignedUrlRaw(
    requestParameters: GetUploadInvoiceSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadInvoiceUrl>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUploadInvoiceSignedUrl().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/upload-invoice-url`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadInvoiceUrlFromJSON(jsonValue),
    );
  }

  /**
   * Returns a signed, temporary bucket url to upload an invoice file
   */
  async getUploadInvoiceSignedUrl(
    requestParameters: GetUploadInvoiceSignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadInvoiceUrl> {
    const response = await this.getUploadInvoiceSignedUrlRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Obtains user account info
   */
  async getUserAccountDetailsRaw(
    requestParameters: GetUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserAccountDetails>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUserAccountDetails().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserAccountDetailsFromJSON(jsonValue),
    );
  }

  /**
   * Obtains user account info
   */
  async getUserAccountDetails(
    requestParameters: GetUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserAccountDetails> {
    const response = await this.getUserAccountDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns house details.
   */
  async getUserHouseRaw(
    requestParameters: GetUserHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserHouseWithIntegrations>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getUserHouse().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/houses/{houseId}`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserHouseWithIntegrationsFromJSON(jsonValue),
    );
  }

  /**
   * Returns house details.
   */
  async getUserHouse(
    requestParameters: GetUserHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserHouseWithIntegrations> {
    const response = await this.getUserHouseRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns the user houses.
   */
  async getUserHousesRaw(
    requestParameters: GetUserHousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserHousePage>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUserHouses().',
      );
    }

    if (requestParameters['page'] == null) {
      throw new runtime.RequiredError(
        'page',
        'Required parameter "page" was null or undefined when calling getUserHouses().',
      );
    }

    if (requestParameters['size'] == null) {
      throw new runtime.RequiredError(
        'size',
        'Required parameter "size" was null or undefined when calling getUserHouses().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['cups'] != null) {
      queryParameters['cups'] = requestParameters['cups'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['size'] != null) {
      queryParameters['size'] = requestParameters['size'];
    }

    if (requestParameters['sort'] != null) {
      queryParameters['sort'] = requestParameters['sort'];
    }

    if (requestParameters['direction'] != null) {
      queryParameters['direction'] = requestParameters['direction'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/users/{userId}/houses`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserHousePageFromJSON(jsonValue),
    );
  }

  /**
   * Returns the user houses.
   */
  async getUserHouses(
    requestParameters: GetUserHousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserHousePage> {
    const response = await this.getUserHousesRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns the user integrations.
   */
  async getUserIntegrationsRaw(
    requestParameters: GetUserIntegrationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserIntegrationsResponse>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUserIntegrations().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/users/{userId}/integrations`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserIntegrationsResponseFromJSON(jsonValue),
    );
  }

  /**
   * Returns the user integrations.
   */
  async getUserIntegrations(
    requestParameters: GetUserIntegrationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserIntegrationsResponse> {
    const response = await this.getUserIntegrationsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get authorized users for a user
   */
  async getUsersAuthorizedByUserRaw(
    requestParameters: GetUsersAuthorizedByUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AuthorizedUser>>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling getUsersAuthorizedByUser().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/authorized-users`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AuthorizedUserFromJSON),
    );
  }

  /**
   * Get authorized users for a user
   */
  async getUsersAuthorizedByUser(
    requestParameters: GetUsersAuthorizedByUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AuthorizedUser>> {
    const response = await this.getUsersAuthorizedByUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * set user\'s status to VALIDATING_HOLDER
   */
  async markAsValidatingHolderRaw(
    requestParameters: MarkAsValidatingHolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling markAsValidatingHolder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-validating-holder`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to VALIDATING_HOLDER
   */
  async markAsValidatingHolder(
    requestParameters: MarkAsValidatingHolderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markAsValidatingHolderRaw(requestParameters, initOverrides);
  }

  /**
   * set user\'s status to WAITING_AUTHORIZATION
   */
  async markAsWaitingAuthorizationRaw(
    requestParameters: MarkAsWaitingAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling markAsWaitingAuthorization().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-waiting-authorization`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to WAITING_AUTHORIZATION
   */
  async markAsWaitingAuthorization(
    requestParameters: MarkAsWaitingAuthorizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markAsWaitingAuthorizationRaw(requestParameters, initOverrides);
  }

  /**
   * set user\'s status to COMPLETED
   */
  async markUserAsCompletedRaw(
    requestParameters: MarkUserAsCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling markUserAsCompleted().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/mark-as-completed`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * set user\'s status to COMPLETED
   */
  async markUserAsCompleted(
    requestParameters: MarkUserAsCompletedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markUserAsCompletedRaw(requestParameters, initOverrides);
  }

  /**
   * Save the user from Firebase after registration.
   */
  async registerUserRaw(
    requestParameters: RegisterUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/user-registration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserRegistrationToJSON(requestParameters['user']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Save the user from Firebase after registration.
   */
  async registerUser(
    requestParameters: RegisterUserRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.registerUserRaw(requestParameters, initOverrides);
  }

  /**
   * Returns the user setup.
   */
  async retrieveUserSetUpRaw(
    requestParameters: RetrieveUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserSetUp>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling retrieveUserSetUp().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/setup`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserSetUpFromJSON(jsonValue),
    );
  }

  /**
   * Returns the user setup.
   */
  async retrieveUserSetUp(
    requestParameters: RetrieveUserSetUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserSetUp> {
    const response = await this.retrieveUserSetUpRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * submits open contract lead
   */
  async submitOpenContractLeadRaw(
    requestParameters: SubmitOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling submitOpenContractLead().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/submit-contract-lead`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * submits open contract lead
   */
  async submitOpenContractLead(
    requestParameters: SubmitOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.submitOpenContractLeadRaw(requestParameters, initOverrides);
  }

  /**
   * Tracks a user login from app
   */
  async trackUserAppLoginRaw(
    requestParameters: TrackUserAppLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling trackUserAppLogin().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/track-app-login`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Tracks a user login from app
   */
  async trackUserAppLogin(
    requestParameters: TrackUserAppLoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.trackUserAppLoginRaw(requestParameters, initOverrides);
  }

  /**
   * creates or updates a contract lead
   */
  async updateOpenContractLeadRaw(
    requestParameters: UpdateOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling updateOpenContractLead().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/open-contract-lead`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateContractLeadToJSON(requestParameters['updateContractLead']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * creates or updates a contract lead
   */
  async updateOpenContractLead(
    requestParameters: UpdateOpenContractLeadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateOpenContractLeadRaw(requestParameters, initOverrides);
  }

  /**
   * Save user preferences notification
   */
  async updatePreferencesNotificationsRaw(
    requestParameters: UpdatePreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling updatePreferencesNotifications().',
      );
    }

    if (requestParameters['type'] == null) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter "type" was null or undefined when calling updatePreferencesNotifications().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/notifications/{type}`
          .replace(
            `{${'userId'}}`,
            encodeURIComponent(String(requestParameters['userId'])),
          )
          .replace(
            `{${'type'}}`,
            encodeURIComponent(String(requestParameters['type'])),
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserPreferencesNotificationRequestToJSON(
          requestParameters['updateUserPreferencesNotifications'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Save user preferences notification
   */
  async updatePreferencesNotifications(
    requestParameters: UpdatePreferencesNotificationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updatePreferencesNotificationsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Updates user account info
   */
  async updateUserAccountDetailsRaw(
    requestParameters: UpdateUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling updateUserAccountDetails().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/users/{userId}`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UserAccountDetailsToJSON(requestParameters['details']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates user account info
   */
  async updateUserAccountDetails(
    requestParameters: UpdateUserAccountDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateUserAccountDetailsRaw(requestParameters, initOverrides);
  }

  /**
   * Validates a previously uploaded user\'s dni.
   */
  async validateDniRaw(
    requestParameters: ValidateDniRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling validateDni().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/validate-dni`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validates a previously uploaded user\'s dni.
   */
  async validateDni(
    requestParameters: ValidateDniRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.validateDniRaw(requestParameters, initOverrides);
  }

  /**
   * Validates a previously uploaded user\'s invoice.
   */
  async validateInvoiceRaw(
    requestParameters: ValidateInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling validateInvoice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/validate-invoice`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Validates a previously uploaded user\'s invoice.
   */
  async validateInvoice(
    requestParameters: ValidateInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.validateInvoiceRaw(requestParameters, initOverrides);
  }
}

/**
 * @export
 */
export const GetPreferencesNotificationsTypeEnum = {
  ConsumptionReport: 'CONSUMPTION_REPORT',
  Export: 'EXPORT',
} as const;
export type GetPreferencesNotificationsTypeEnum =
  (typeof GetPreferencesNotificationsTypeEnum)[keyof typeof GetPreferencesNotificationsTypeEnum];
/**
 * @export
 */
export const UpdatePreferencesNotificationsTypeEnum = {
  Export: 'EXPORT',
  ConsumptionReport: 'CONSUMPTION_REPORT',
} as const;
export type UpdatePreferencesNotificationsTypeEnum =
  (typeof UpdatePreferencesNotificationsTypeEnum)[keyof typeof UpdatePreferencesNotificationsTypeEnum];
