import { Button } from '@clevergy/ui/components/Button';
import { Heading } from '@clevergy/ui/components/Heading';
import { ProcessIconSuccess } from '@clevergy/ui/components/ProcessIconSuccess';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type SuccessScreenProps = {
  title: ReactNode;
  description: ReactNode;
};

export const SuccessScreen: FC<SuccessScreenProps> = (props) => {
  const { title, description } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedHouse } = useSelectedHouse();

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="grow flex flex-col text-center items-center justify-center px-8 gap-4">
        <ProcessIconSuccess />

        <div>
          <Heading as="h2" colorScheme="neutral">
            {title}
          </Heading>
          <>{description}</>
        </div>
        <Button
          onClick={() =>
            navigate(`/houses/${selectedHouse?.details.houseId}`, {
              replace: true,
            })
          }
        >
          {t('oauth.huawei.successScreen.button')}
        </Button>
      </div>
    </div>
  );
};
