import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import BreakdownModuleDraft from 'assets/illustrations/breakdown-module-draft.svg?react';
import { useUserInfo } from 'context/UserInfoContext';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DraftModuleBreakdown = () => {
  const { userInfo } = useUserInfo();
  const {
    shouldSuggestSmartMeterIntegration,
    shouldShowSmartMeterDraftModules,
    isSmartMeterIntegrationInProgress,
  } = useSmartMeterIntegration();

  const { t } = useTranslation();

  if (userInfo.isCompany) {
    return null;
  }

  if (!shouldShowSmartMeterDraftModules) {
    return null;
  }

  return (
    <ModuleCard>
      <ModuleCard.Header
        title="Tu consumo explicado"
        subtitle="Averigua qué electrodomésticos consumen más"
      />
      <ModuleCard.Content>
        <div className="flex flex-col gap-4">
          <div className="relative">
            <BreakdownModuleDraft />
            {isSmartMeterIntegrationInProgress && (
              <div className="absolute inset-0 flex justify-center items-center text-center px-4 bg-white/70">
                {t('draftModules.waitingConnection')}
              </div>
            )}
          </div>
          {shouldSuggestSmartMeterIntegration && (
            <Link
              to={`/integrations/datadis`}
              className="underline text-clevergy-primary text-right font-semibold"
            >
              Ver electrodomésticos con más consumo
            </Link>
          )}
        </div>
      </ModuleCard.Content>
    </ModuleCard>
  );
};
