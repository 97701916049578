/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface AuthorizedUser
 */
export interface AuthorizedUser {
  /**
   *
   * @type {string}
   * @memberof AuthorizedUser
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof AuthorizedUser
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof AuthorizedUser
   */
  authorizedUserEmail: string;
  /**
   *
   * @type {Date}
   * @memberof AuthorizedUser
   */
  authorizationDate: Date;
  /**
   *
   * @type {string}
   * @memberof AuthorizedUser
   */
  status: AuthorizedUserStatusEnum;
}

/**
 * @export
 */
export const AuthorizedUserStatusEnum = {
  Authorized: 'AUTHORIZED',
  Revoked: 'REVOKED',
} as const;
export type AuthorizedUserStatusEnum =
  (typeof AuthorizedUserStatusEnum)[keyof typeof AuthorizedUserStatusEnum];

/**
 * Check if a given object implements the AuthorizedUser interface.
 */
export function instanceOfAuthorizedUser(
  value: object,
): value is AuthorizedUser {
  if (!('userId' in value) || value['userId'] === undefined) return false;
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (
    !('authorizedUserEmail' in value) ||
    value['authorizedUserEmail'] === undefined
  )
    return false;
  if (
    !('authorizationDate' in value) ||
    value['authorizationDate'] === undefined
  )
    return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function AuthorizedUserFromJSON(json: any): AuthorizedUser {
  return AuthorizedUserFromJSONTyped(json, false);
}

export function AuthorizedUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthorizedUser {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'],
    houseId: json['houseId'],
    authorizedUserEmail: json['authorizedUserEmail'],
    authorizationDate: new Date(json['authorizationDate']),
    status: json['status'],
  };
}

export function AuthorizedUserToJSON(json: any): AuthorizedUser {
  return AuthorizedUserToJSONTyped(json, false);
}

export function AuthorizedUserToJSONTyped(
  value?: AuthorizedUser | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userId: value['userId'],
    houseId: value['houseId'],
    authorizedUserEmail: value['authorizedUserEmail'],
    authorizationDate: value['authorizationDate'].toISOString(),
    status: value['status'],
  };
}
