import { ConnectedState } from './components/ConnectedState';
import { NoConnectedState } from './components/NoConnectedState';
import {
  GetSmartDevicesResponse,
  GetSmartDevicesResponseSubtypeEnum,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { skipToken, useQuery } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const SmartDevicesPage: FC = () => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();

  const smartDevicesQuery = useQuery({
    queryKey: [
      QueryKeys.GET_SMART_DEVICES,
      { houseId: selectedHouse?.details.houseId },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.smartDevices.getSmartDevices({
            houseId: selectedHouse.details.houseId,
          })
      : skipToken,
    refetchOnWindowFocus: true,
    staleTime: 0,
    refetchInterval: 30 * 1000,
  });

  const devices = useMemo(() => {
    if (!smartDevicesQuery.data || smartDevicesQuery.data.length === 0) {
      return [];
    }

    return smartDevicesQuery.data.sort((a: GetSmartDevicesResponse) =>
      a.subtype === GetSmartDevicesResponseSubtypeEnum.Unknown ? -1 : 1,
    );
  }, [smartDevicesQuery.data]);

  if (smartDevicesQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('smartDevices.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {devices.length === 0 ? (
          <NoConnectedState />
        ) : (
          <ConnectedState devices={devices} />
        )}
      </Wrapper>
    </Page>
  );
};
