import { useApiContext } from './ApiContext';
import { useAuthContext } from './AuthContext';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { validateCIF } from '@clevergy/utils/validations/validateCIF';
import { useQuery } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

export type UserInfoContextValue = {
  userInfo: {
    name?: string;
    surname?: string;
    email?: string;
    dni?: string;
    isCompany?: boolean;
  };
  refetch: () => Promise<void>;
};

export const UserInfoContext = createContext<UserInfoContextValue | null>(null);

export const UserInfoContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  // Query for the user account details
  const userAccountInfoQuery = useQuery({
    queryKey: [
      QueryKeys.GET_USER_ACCOUNT_DETAILS,
      {
        userId: authedUser?.uid || 'me',
      },
    ],
    queryFn: () =>
      api.users.getUserAccountDetails({
        userId: authedUser?.uid || 'me',
      }),
    refetchInterval: 60 * 1000,
    enabled: Boolean(authedUser?.uid),
    gcTime: 0,
    staleTime: 0,
  });

  const userInfo = useMemo(() => {
    return {
      name: userAccountInfoQuery.data?.name,
      surname: userAccountInfoQuery.data?.surname,
      email: userAccountInfoQuery.data?.email,
      dni: userAccountInfoQuery.data?.dni,
      phoneNumber: userAccountInfoQuery.data?.phoneNumber,
      isCompany: userAccountInfoQuery.data?.dni
        ? validateCIF(userAccountInfoQuery.data?.dni)
        : false,
    };
  }, [userAccountInfoQuery.data]);

  const value: UserInfoContextValue = useMemo(
    () => ({
      userInfo,
      refetch: async () => {
        userAccountInfoQuery.refetch();
      },
    }),
    [userAccountInfoQuery, userInfo],
  );

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () => {
  const context = useContext(UserInfoContext);
  if (context === null) {
    throw Error('useUserInfoContext must be used inside of a UserInfoContext');
  }
  return context;
};
