/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface HouseIntegration
 */
export interface HouseIntegration {
  /**
   *
   * @type {string}
   * @memberof HouseIntegration
   */
  status: HouseIntegrationStatusEnum;
}

/**
 * @export
 */
export const HouseIntegrationStatusEnum = {
  None: 'NONE',
  Connected: 'CONNECTED',
} as const;
export type HouseIntegrationStatusEnum =
  (typeof HouseIntegrationStatusEnum)[keyof typeof HouseIntegrationStatusEnum];

/**
 * Check if a given object implements the HouseIntegration interface.
 */
export function instanceOfHouseIntegration(
  value: object,
): value is HouseIntegration {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function HouseIntegrationFromJSON(json: any): HouseIntegration {
  return HouseIntegrationFromJSONTyped(json, false);
}

export function HouseIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseIntegration {
  if (json == null) {
    return json;
  }
  return {
    status: json['status'],
  };
}

export function HouseIntegrationToJSON(json: any): HouseIntegration {
  return HouseIntegrationToJSONTyped(json, false);
}

export function HouseIntegrationToJSONTyped(
  value?: HouseIntegration | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: value['status'],
  };
}
