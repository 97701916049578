import {
  GetSmartDevicesResponseSubtypeEnum,
  UpdateSmartDeviceRequestSubtypeEnum,
} from '@clevergy/api-client';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const MainMeasuringPointEnum = {
  HouseholdAppliance: 'HOUSEHOLD_APPLIANCE',
} as const;

export type MainMeasuringPointEnum =
  (typeof MainMeasuringPointEnum)[keyof typeof MainMeasuringPointEnum];

export const useSmartDeviceFormLists = () => {
  const { t } = useTranslation();
  const { selectedHouse } = useSelectedHouse();

  const mainMeasuringPointList = useMemo(() => {
    const hasSolar = Boolean(selectedHouse?.houseMetadata.lastDatePower);

    return [
      {
        id: UpdateSmartDeviceRequestSubtypeEnum.Aerothermal,
        text: t('smartDevices.form.fields.mainMeasuringPoint.text.AEROTHERMAL'),
      },
      {
        id: UpdateSmartDeviceRequestSubtypeEnum.Battery,
        text: t('smartDevices.form.fields.mainMeasuringPoint.text.BATTERY'),
      },
      {
        id: UpdateSmartDeviceRequestSubtypeEnum.Grid,
        text: hasSolar
          ? t('smartDevices.form.fields.mainMeasuringPoint.text.CONSUMPTION')
          : t('smartDevices.form.fields.mainMeasuringPoint.text.GRID'),
      },
      {
        id: MainMeasuringPointEnum.HouseholdAppliance,
        text: t(
          'smartDevices.form.fields.mainMeasuringPoint.text.HOUSEHOLD_APPLIANCE',
        ),
      },
      {
        id: UpdateSmartDeviceRequestSubtypeEnum.Export,
        text: t('smartDevices.form.fields.mainMeasuringPoint.text.EXPORT'),
      },
      {
        id: UpdateSmartDeviceRequestSubtypeEnum.SelfProduction,
        text: t(
          'smartDevices.form.fields.mainMeasuringPoint.text.SELF_PRODUCTION',
        ),
      },
    ];
  }, [selectedHouse?.houseMetadata.lastDatePower, t]);

  const smartDevicesSubtypesList = useMemo(() => {
    return [
      {
        id: GetSmartDevicesResponseSubtypeEnum.Ac,
        text: t('smartDevices.smartDevicesSubtypes.AC'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Vacuum,
        text: t('smartDevices.smartDevicesSubtypes.VACUUM'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Heater,
        text: t('smartDevices.smartDevicesSubtypes.HEATER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Freezer,
        text: t('smartDevices.smartDevicesSubtypes.FREEZER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Pool,
        text: t('smartDevices.smartDevicesSubtypes.POOL'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.PlugAc,
        text: t('smartDevices.smartDevicesSubtypes.PLUG_AC'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.PlugHeater,
        text: t('smartDevices.smartDevicesSubtypes.PLUG_HEATER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Fridge,
        text: t('smartDevices.smartDevicesSubtypes.FRIDGE'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Oven,
        text: t('smartDevices.smartDevicesSubtypes.OVEN'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.WashingMachine,
        text: t('smartDevices.smartDevicesSubtypes.WASHING_MACHINE'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Dishwasher,
        text: t('smartDevices.smartDevicesSubtypes.DISHWASHER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Light,
        text: t('smartDevices.smartDevicesSubtypes.LIGHT'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Microwave,
        text: t('smartDevices.smartDevicesSubtypes.MICROWAVE'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Workstation,
        text: t('smartDevices.smartDevicesSubtypes.WORKSTATION'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Other,
        text: t('smartDevices.smartDevicesSubtypes.OTHER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Dryer,
        text: t('smartDevices.smartDevicesSubtypes.DRYER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Tv,
        text: t('smartDevices.smartDevicesSubtypes.TV'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.WaterHeater,
        text: t('smartDevices.smartDevicesSubtypes.WATER_HEATER'),
      },
      {
        id: GetSmartDevicesResponseSubtypeEnum.Kitchen,
        text: t('smartDevices.smartDevicesSubtypes.KITCHEN'),
      },
    ];
  }, [t]);

  return {
    mainMeasuringPointList,
    smartDevicesSubtypesList,
  };
};
