import { PreferencesNotificationToggle } from './components/PreferencesNotificationToggle';
import { GetPreferencesNotificationsTypeEnum } from '@clevergy/api-client';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useNotificationsContext } from 'context/NotificationsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlertsPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();

  const { permissionsStatus, requestNotificationsPermission } =
    useNotificationsContext();

  const canRequestNotificationsPermission =
    permissionsStatus && permissionsStatus !== 'unavailable';

  return (
    <Page>
      <PageHeader
        backButton
        title={t('alerts.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {token && (
          <>
            {selectedHouse && (
              <clevergy-alerts-settings
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {canRequestNotificationsPermission && (
              <ModuleCard>
                <ModuleCard.Header title={t('alerts.notifications.title')} />
                <ModuleCard.Content>
                  <p className="mb-4">
                    {t('alerts.notifications.subtitle.part1')}{' '}
                    <button
                      onClick={() => requestNotificationsPermission()}
                      className="text-clevergy-primary underline"
                    >
                      {t('alerts.notifications.subtitle.phoneSettingsLink')}
                    </button>{' '}
                    {t('alerts.notifications.subtitle.part2')}
                  </p>

                  <div className="flex flex-col gap-4">
                    <PreferencesNotificationToggle
                      type={
                        GetPreferencesNotificationsTypeEnum.ConsumptionReport
                      }
                      title={
                        t(
                          'alerts.notifications.types.consumptionReport.title',
                        ) as string
                      }
                      description={
                        t(
                          'alerts.notifications.types.consumptionReport.description',
                        ) as string
                      }
                    />
                    <PreferencesNotificationToggle
                      type={GetPreferencesNotificationsTypeEnum.Export}
                      title={
                        t('alerts.notifications.types.export.title') as string
                      }
                      description={
                        t(
                          'alerts.notifications.types.export.description',
                        ) as string
                      }
                    />
                  </div>
                </ModuleCard.Content>
              </ModuleCard>
            )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
