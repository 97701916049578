import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { Alert } from '@clevergy/ui/components/Alert';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useUserInfo } from 'context/UserInfoContext';
import { differenceInDays } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AppAlerts: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { userInfo } = useUserInfo();
  const { selectedHouse } = useSelectedHouse();

  const houseSettingsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_HOUSE_SETTINGS,
      { houseId: selectedHouse?.details.houseId },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.settings.getHouseSettings({
            houseId: selectedHouse.details.houseId,
          })
      : skipToken,
  });

  const alerts = {
    houseSettingsAlert:
      !userInfo.isCompany &&
      !houseSettingsQuery.isPending &&
      houseSettingsQuery.isError,

    consumptionDataOutdatedAlert:
      selectedHouse?.houseMetadata.lastDateEnergy &&
      differenceInDays(
        new Date(),
        selectedHouse?.houseMetadata.lastDateEnergy,
      ) >= 14,
  };

  const hasAnyAlertToShow = Object.values(alerts).some(Boolean);

  if (!hasAnyAlertToShow) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 mb-4">
      {alerts.houseSettingsAlert && selectedHouse && (
        <Alert
          type="warning"
          text={t('applicationAlerts.missingHouseSettings.text') as string}
          actions={
            <Link to={`/houses/${selectedHouse.details.houseId}/settings`}>
              {t('applicationAlerts.missingHouseSettings.link')}
            </Link>
          }
        />
      )}
      {alerts.consumptionDataOutdatedAlert && (
        <Alert
          type="help"
          title={t('applicationAlerts.consumptionDataOutdated.title') as string}
          text={t('applicationAlerts.consumptionDataOutdated.text') as string}
          icon={<FontIcon name="signal_disconnected" />}
        />
      )}
    </div>
  );
};
