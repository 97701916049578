/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { HouseIntegrations } from './HouseIntegrations';
import {
  HouseIntegrationsFromJSON,
  HouseIntegrationsFromJSONTyped,
  HouseIntegrationsToJSON,
  HouseIntegrationsToJSONTyped,
} from './HouseIntegrations';
import type { UserHouseDetails } from './UserHouseDetails';
import {
  UserHouseDetailsFromJSON,
  UserHouseDetailsFromJSONTyped,
  UserHouseDetailsToJSON,
  UserHouseDetailsToJSONTyped,
} from './UserHouseDetails';
import type { UserHouseHouseMetadata } from './UserHouseHouseMetadata';
import {
  UserHouseHouseMetadataFromJSON,
  UserHouseHouseMetadataFromJSONTyped,
  UserHouseHouseMetadataToJSON,
  UserHouseHouseMetadataToJSONTyped,
} from './UserHouseHouseMetadata';

/**
 *
 * @export
 * @interface UserHouseWithIntegrations
 */
export interface UserHouseWithIntegrations {
  /**
   *
   * @type {UserHouseDetails}
   * @memberof UserHouseWithIntegrations
   */
  details: UserHouseDetails;
  /**
   *
   * @type {UserHouseHouseMetadata}
   * @memberof UserHouseWithIntegrations
   */
  houseMetadata: UserHouseHouseMetadata;
  /**
   *
   * @type {HouseIntegrations}
   * @memberof UserHouseWithIntegrations
   */
  houseIntegrations: HouseIntegrations;
}

/**
 * Check if a given object implements the UserHouseWithIntegrations interface.
 */
export function instanceOfUserHouseWithIntegrations(
  value: object,
): value is UserHouseWithIntegrations {
  if (!('details' in value) || value['details'] === undefined) return false;
  if (!('houseMetadata' in value) || value['houseMetadata'] === undefined)
    return false;
  if (
    !('houseIntegrations' in value) ||
    value['houseIntegrations'] === undefined
  )
    return false;
  return true;
}

export function UserHouseWithIntegrationsFromJSON(
  json: any,
): UserHouseWithIntegrations {
  return UserHouseWithIntegrationsFromJSONTyped(json, false);
}

export function UserHouseWithIntegrationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserHouseWithIntegrations {
  if (json == null) {
    return json;
  }
  return {
    details: UserHouseDetailsFromJSON(json['details']),
    houseMetadata: UserHouseHouseMetadataFromJSON(json['houseMetadata']),
    houseIntegrations: HouseIntegrationsFromJSON(json['houseIntegrations']),
  };
}

export function UserHouseWithIntegrationsToJSON(
  json: any,
): UserHouseWithIntegrations {
  return UserHouseWithIntegrationsToJSONTyped(json, false);
}

export function UserHouseWithIntegrationsToJSONTyped(
  value?: UserHouseWithIntegrations | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    details: UserHouseDetailsToJSON(value['details']),
    houseMetadata: UserHouseHouseMetadataToJSON(value['houseMetadata']),
    houseIntegrations: HouseIntegrationsToJSON(value['houseIntegrations']),
  };
}
