import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type EmptyScreenProps = {
  onAddAuthorizedUser: () => void;
};

export const EmptyScreen: FC<EmptyScreenProps> = ({ onAddAuthorizedUser }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 items-center my-4 text-center">
      <div className="text-clevergy-primary">
        <FontIcon
          size={56}
          name="info"
          variant="filled"
          circleBackgroundClass="bg-clevergy-primary/10"
        />
      </div>
      <div>
        <Heading as="h3" centered colorScheme="neutral">
          {t('sharedAccess.emptyScreen.title')}
        </Heading>
        <p>{t('sharedAccess.emptyScreen.description')}</p>
      </div>
      <Button variant="filled" fullWidth onClick={onAddAuthorizedUser}>
        {t('sharedAccess.emptyScreen.addAuthorizedUserButton')}
      </Button>
    </div>
  );
};
