/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserIntegration
 */
export interface UserIntegration {
  /**
   *
   * @type {string}
   * @memberof UserIntegration
   */
  status?: UserIntegrationStatusEnum;
}

/**
 * @export
 */
export const UserIntegrationStatusEnum = {
  None: 'NONE',
  Connected: 'CONNECTED',
} as const;
export type UserIntegrationStatusEnum =
  (typeof UserIntegrationStatusEnum)[keyof typeof UserIntegrationStatusEnum];

/**
 * Check if a given object implements the UserIntegration interface.
 */
export function instanceOfUserIntegration(
  value: object,
): value is UserIntegration {
  return true;
}

export function UserIntegrationFromJSON(json: any): UserIntegration {
  return UserIntegrationFromJSONTyped(json, false);
}

export function UserIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserIntegration {
  if (json == null) {
    return json;
  }
  return {
    status: json['status'] == null ? undefined : json['status'],
  };
}

export function UserIntegrationToJSON(json: any): UserIntegration {
  return UserIntegrationToJSONTyped(json, false);
}

export function UserIntegrationToJSONTyped(
  value?: UserIntegration | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    status: value['status'],
  };
}
