import { AuthorizedUserStatusEnum } from '@clevergy/api-client';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Modal } from '@clevergy/ui/components/Modal';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type RevokeAccessModalProps = {
  onCancel: () => void;
  onAccessRevoked: () => void;
  userAuthorizedEmail: string;
  houseId: string;
  houseName?: string;
};

export const RevokeAccessModal: FC<RevokeAccessModalProps> = ({
  onCancel,
  onAccessRevoked,
  userAuthorizedEmail,
  houseId,
  houseName,
}) => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const revokeAccessMutation = useMutation({
    mutationFn: () => {
      if (!authedUser?.uid) {
        throw new Error('User ID is required');
      }
      return api.users.changeStatusForUserAuthorization({
        userId: authedUser.uid,
        changeAuthorizationUserRequest: {
          houseId,
          userAuthorizedEmail,
          status: AuthorizedUserStatusEnum.Revoked,
        },
      });
    },
  });

  const handleRevokeAccess = async () => {
    await revokeAccessMutation.mutateAsync();
    onAccessRevoked();
  };

  return (
    <Modal isOpen={true} onClose={() => onCancel()}>
      <div className="h-full flex flex-col items-center gap-8 text-center">
        <div className="grow flex flex-col justify-center items-center">
          <div className="text-red-600">
            <FontIcon
              name="error"
              size={56}
              variant="filled"
              circleBackgroundClass="bg-red-100"
            />
          </div>
          <p className="text-xl font-semibold text-center py-4">
            {t('sharedAccess.revokeAccessModal.title')}
          </p>
          <p className="text-base text-neutral-700 text-center px-4">
            <Trans
              i18nKey="sharedAccess.revokeAccessModal.description"
              shouldUnescape
              values={{
                email: userAuthorizedEmail,
                houseName: houseName || 'Sin dirección',
              }}
            />
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Button
            variant="flat"
            colorScheme="danger"
            fullWidth
            onClick={onCancel}
          >
            {t('sharedAccess.revokeAccessModal.cancelButton')}
          </Button>

          <Button
            fullWidth
            variant="filled"
            colorScheme="danger"
            onClick={handleRevokeAccess}
            busy={revokeAccessMutation.isPending}
            disabled={revokeAccessMutation.isPending}
          >
            {t('sharedAccess.revokeAccessModal.submitButton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
