/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { UserHouse } from './UserHouse';
import {
  UserHouseFromJSON,
  UserHouseFromJSONTyped,
  UserHouseToJSON,
  UserHouseToJSONTyped,
} from './UserHouse';

/**
 * House response page
 * @export
 * @interface UserHousePage
 */
export interface UserHousePage {
  /**
   *
   * @type {number}
   * @memberof UserHousePage
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof UserHousePage
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof UserHousePage
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof UserHousePage
   */
  totalElements: number;
  /**
   *
   * @type {Array<UserHouse>}
   * @memberof UserHousePage
   */
  elements: Array<UserHouse>;
}

/**
 * Check if a given object implements the UserHousePage interface.
 */
export function instanceOfUserHousePage(value: object): value is UserHousePage {
  if (!('size' in value) || value['size'] === undefined) return false;
  if (!('page' in value) || value['page'] === undefined) return false;
  if (!('totalPages' in value) || value['totalPages'] === undefined)
    return false;
  if (!('totalElements' in value) || value['totalElements'] === undefined)
    return false;
  if (!('elements' in value) || value['elements'] === undefined) return false;
  return true;
}

export function UserHousePageFromJSON(json: any): UserHousePage {
  return UserHousePageFromJSONTyped(json, false);
}

export function UserHousePageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserHousePage {
  if (json == null) {
    return json;
  }
  return {
    size: json['size'],
    page: json['page'],
    totalPages: json['totalPages'],
    totalElements: json['totalElements'],
    elements: (json['elements'] as Array<any>).map(UserHouseFromJSON),
  };
}

export function UserHousePageToJSON(json: any): UserHousePage {
  return UserHousePageToJSONTyped(json, false);
}

export function UserHousePageToJSONTyped(
  value?: UserHousePage | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    size: value['size'],
    page: value['page'],
    totalPages: value['totalPages'],
    totalElements: value['totalElements'],
    elements: (value['elements'] as Array<any>).map(UserHouseToJSON),
  };
}
