import { useApiContext } from './ApiContext';
import { useAuthContext } from './AuthContext';
import { UserHouse } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { skipToken, useQuery } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

export type UserHousesContextValue = {
  houses: UserHouse[] | undefined;
  refetch: () => Promise<void>;
};

export const UserHousesContext = createContext<UserHousesContextValue | null>(
  null,
);

export const UserHousesContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  // Query for the user's houses
  const userHousesQuery = useQuery({
    queryKey: [QueryKeys.GET_USER_HOUSES, { userId: authedUser?.uid }],
    queryFn: authedUser?.uid
      ? () =>
          api.users.getUserHouses({
            userId: authedUser?.uid,
            page: 1,
            size: 100,
          })
      : skipToken,
  });

  const value: UserHousesContextValue = useMemo(
    () => ({
      houses: userHousesQuery.data?.elements,
      refetch: async () => {
        userHousesQuery.refetch();
      },
    }),
    [userHousesQuery],
  );

  return (
    <UserHousesContext.Provider value={value}>
      {children}
    </UserHousesContext.Provider>
  );
};

export const useUserHouses = () => {
  const context = useContext(UserHousesContext);
  if (context === null) {
    throw Error(
      'useUserHousesContext must be used inside of a UserHousesContext',
    );
  }
  return context;
};
