import { SmartDeviceNotFoundPage } from './SmartDeviceNotFoundPage';
import {
  DeviceFormValues,
  SmartDeviceForm,
} from './components/SmartDeviceForm';
import {
  UpdateSmartDeviceOperationRequest,
  UpdateSmartDeviceRequestSubtypeEnum,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';

export const SmartDevicePage: FC = () => {
  const { deviceId, channel } = useParams();
  const { api } = useApiContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectedHouse } = useSelectedHouse();

  const smartDeviceQuery = useQuery({
    queryKey: [
      QueryKeys.GET_SMART_DEVICE,
      { houseId: selectedHouse?.details.houseId, deviceId, channel },
    ],
    queryFn:
      selectedHouse?.details.houseId && deviceId
        ? () =>
            api.smartDevices.getSmartDeviceDetails({
              houseId: selectedHouse.details.houseId,
              deviceId,
              channel: channel ? parseInt(channel) : 0,
            })
        : skipToken,
  });

  const updateSmartDeviceMutation = useMutation({
    mutationFn: (payload: UpdateSmartDeviceOperationRequest) => {
      return api.smartDevices.updateSmartDevice(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.GET_SMART_DEVICE,
          { houseId: selectedHouse?.details.houseId, deviceId },
        ],
      });

      navigate(`/houses/${selectedHouse?.details.houseId}/smart-devices`);
    },
  });

  const handleSubmitDeviceForm = async (data: DeviceFormValues) => {
    updateSmartDeviceMutation.mutate({
      houseId: data.houseId,
      deviceId: data.deviceId,
      channel: data.channel,
      updateSmartDeviceRequest: {
        subtype: data.subtype as UpdateSmartDeviceRequestSubtypeEnum,
      },
    });
  };

  if (smartDeviceQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  if (selectedHouse && !smartDeviceQuery.data) {
    return <SmartDeviceNotFoundPage deviceId={deviceId} channel={channel} />;
  }

  if (selectedHouse && smartDeviceQuery.data) {
    return (
      <Page navbarLayoutContentMobileBackground="white">
        <PageHeader backButton title={smartDeviceQuery.data.name} />

        <Wrapper>
          <SmartDeviceForm
            houseId={selectedHouse.details.houseId}
            device={smartDeviceQuery.data}
            isMutationPending={updateSmartDeviceMutation.isPending}
            onUpdateSmartDevice={handleSubmitDeviceForm}
          />
        </Wrapper>
      </Page>
    );
  }
};
