/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { HouseIntegration } from './HouseIntegration';
import {
  HouseIntegrationFromJSON,
  HouseIntegrationFromJSONTyped,
  HouseIntegrationToJSON,
  HouseIntegrationToJSONTyped,
} from './HouseIntegration';

/**
 *
 * @export
 * @interface HouseIntegrations
 */
export interface HouseIntegrations {
  /**
   *
   * @type {HouseIntegration}
   * @memberof HouseIntegrations
   */
  huaweiB2C?: HouseIntegration;
}

/**
 * Check if a given object implements the HouseIntegrations interface.
 */
export function instanceOfHouseIntegrations(
  value: object,
): value is HouseIntegrations {
  return true;
}

export function HouseIntegrationsFromJSON(json: any): HouseIntegrations {
  return HouseIntegrationsFromJSONTyped(json, false);
}

export function HouseIntegrationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseIntegrations {
  if (json == null) {
    return json;
  }
  return {
    huaweiB2C:
      json['huaweiB2C'] == null
        ? undefined
        : HouseIntegrationFromJSON(json['huaweiB2C']),
  };
}

export function HouseIntegrationsToJSON(json: any): HouseIntegrations {
  return HouseIntegrationsToJSONTyped(json, false);
}

export function HouseIntegrationsToJSONTyped(
  value?: HouseIntegrations | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    huaweiB2C: HouseIntegrationToJSON(value['huaweiB2C']),
  };
}
