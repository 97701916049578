import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

/*
TODO: remove this context once we have a new way to handle Alexa authorization
*/

export type AlexaAuthorization = {
  state: string;
  redirect_uri: string;
};

export type UserSetupContextValue = {
  alexaAuthorization: AlexaAuthorization | null;
  setAlexaAuthorization: (alexaAuthorization: AlexaAuthorization) => void;
};

export const UserSetupContext = createContext<UserSetupContextValue | null>(
  null,
);

export const UserSetupProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alexaAuthorization, setAlexaAuthorization] =
    useState<AlexaAuthorization | null>(null);

  const value: UserSetupContextValue = useMemo(
    () => ({
      alexaAuthorization,
      setAlexaAuthorization,
    }),
    [alexaAuthorization],
  );

  return (
    <UserSetupContext.Provider value={value}>
      {children}
    </UserSetupContext.Provider>
  );
};

export const useUserSetupContext = () => {
  const context = useContext(UserSetupContext);
  if (context === null) {
    throw Error(
      'useUserSetupContext must be used inside of a UserSetupContext',
    );
  }
  return context;
};
