import { IntegrationUrl } from './useOpenIntegrationUrl';
import {
  GetOauthLoginUrlVendorEnum,
  HouseIntegrationStatusEnum,
  UserIntegrationStatusEnum,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { Icon } from '@clevergy/ui/components/Icon';
import { skipToken } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserIntegrationsContext } from 'context/UserIntegrationsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAlexaIntegrationConnectUrl } from 'utils/getAlexaIntegrationConnectUrl';
import { getShellyIntegrationConnectUrl } from 'utils/getShellyIntegrationConnectUrl';

export enum IntegrationId {
  Alexa = 'alexa',
  Shelly = 'shelly',
  HuaweiB2C = 'huaweiB2C',
  Datadis = 'datadis',
}

export type IntegrationItem = {
  id: IntegrationId;
  icon: ReactNode;
  title: string;
  isConnected: boolean;
  /**
   * URL for the integration connect. Usually external URL for Alexa app, Shelly integrator API or Huawei OAuth login URL
   */
  urlForConnect: IntegrationUrl | null;
  /**
   * URL for the integration explanation page with instructions before opening the connect URL
   */
  urlForExplanation?: IntegrationUrl | null;
  /**
   * URL for the integration details page after connection. Usually internal URL for smart devices page or Huawei station details page
   */
  urlForDetails?: IntegrationUrl | null;
};

export function useIntegrations(): {
  isPending: boolean;
  integrationsList: IntegrationItem[];
} {
  const { consumerAppSettings } = useTenantSettings();
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();
  const { userIntegrations } = useUserIntegrationsContext();

  // Query for smart devices
  const smartDevicesQuery = useQuery({
    queryKey: [
      QueryKeys.GET_SMART_DEVICES,
      { houseId: selectedHouse?.details.houseId },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.smartDevices.getSmartDevices({
            houseId: selectedHouse.details.houseId,
          })
      : skipToken,
    refetchOnWindowFocus: true,
    staleTime: 0,
    refetchInterval: 30 * 1000,
  });

  // Query for Huawei integration login url: TODO: remove this query when house integration is implemented
  const huaweiIntegrationQuery = useQuery({
    queryKey: [
      QueryKeys.GET_INTEGRATION_OAUTH_LOGIN_URL,
      {
        houseId: selectedHouse?.details.houseId,
        vendor: GetOauthLoginUrlVendorEnum.HuaweiOauth,
      },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.integrations.getOauthLoginUrl({
            houseId: selectedHouse.details.houseId,
            vendor: GetOauthLoginUrlVendorEnum.HuaweiOauth,
          })
      : skipToken,
  });

  const integrationsList = useMemo(() => {
    const list: IntegrationItem[] = [];

    if (selectedHouse && userIntegrations?.alexa) {
      list.push({
        id: IntegrationId.Alexa,
        icon: <Icon name="Alexa" />,
        title: t('integrations.vendors.alexa'),
        isConnected: Boolean(
          userIntegrations?.alexa.status ===
            UserIntegrationStatusEnum.Connected,
        ),
        urlForConnect: {
          url: getAlexaIntegrationConnectUrl(),
          openInAppBrowser: false,
        },
        urlForDetails: {
          url: '/alexa',
        },
      });
    }

    if (selectedHouse && typeof smartDevicesQuery.data !== 'undefined') {
      list.push({
        id: IntegrationId.Shelly,
        icon: <Icon name="Shelly" />,
        title: t('integrations.vendors.shelly'),
        isConnected: Boolean(
          smartDevicesQuery.data && smartDevicesQuery.data.length > 0,
        ),
        urlForConnect: {
          url: getShellyIntegrationConnectUrl(selectedHouse),
          openInAppBrowser: true,
        },
        urlForExplanation: {
          url: `/integrations/shelly`,
        },
        urlForDetails: {
          url: `/smart-devices`,
        },
      });
    }

    if (
      selectedHouse &&
      selectedHouse.houseIntegrations.huaweiB2C &&
      huaweiIntegrationQuery.data?.url
    ) {
      list.push({
        id: IntegrationId.HuaweiB2C,
        icon: <Icon name="Huawei" />,
        title: t('integrations.vendors.huaweiB2C'),
        isConnected:
          selectedHouse.houseIntegrations.huaweiB2C.status ===
          HouseIntegrationStatusEnum.Connected,
        urlForExplanation: {
          url: `/integrations/huawei`,
        },
        urlForConnect: {
          url: `${huaweiIntegrationQuery.data.url}&redirect_uri=${consumerAppSettings?.deepLinkBaseUrl}/oauth/huawei`,
          openInAppBrowser: true,
        },
        urlForDetails: null,
      });
    }

    if (userIntegrations?.datadis) {
      list.push({
        id: IntegrationId.Datadis,
        icon: <Icon name="Datadis" />,
        title: t('integrations.vendors.datadis'),
        isConnected:
          userIntegrations?.datadis.status ===
          UserIntegrationStatusEnum.Connected,
        urlForExplanation: {
          url: `/integrations/datadis`,
        },
        urlForConnect: null,
        urlForDetails: null,
      });
    }

    return list;
  }, [
    selectedHouse,
    userIntegrations?.alexa,
    userIntegrations?.datadis,
    huaweiIntegrationQuery.data?.url,
    t,
    smartDevicesQuery.data,
    consumerAppSettings?.deepLinkBaseUrl,
  ]);

  const isPending =
    selectedHouse !== null &&
    (smartDevicesQuery.isPending || huaweiIntegrationQuery.isPending);

  return {
    isPending,
    integrationsList,
  };
}
