/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserHouseHouseMetadata
 */
export interface UserHouseHouseMetadata {
  /**
   *
   * @type {Date}
   * @memberof UserHouseHouseMetadata
   */
  firstDateEnergy?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserHouseHouseMetadata
   */
  lastDateEnergy?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserHouseHouseMetadata
   */
  firstDatePower?: Date;
  /**
   *
   * @type {Date}
   * @memberof UserHouseHouseMetadata
   */
  lastDatePower?: Date;
  /**
   *
   * @type {boolean}
   * @memberof UserHouseHouseMetadata
   */
  isOwner?: boolean;
}

/**
 * Check if a given object implements the UserHouseHouseMetadata interface.
 */
export function instanceOfUserHouseHouseMetadata(
  value: object,
): value is UserHouseHouseMetadata {
  return true;
}

export function UserHouseHouseMetadataFromJSON(
  json: any,
): UserHouseHouseMetadata {
  return UserHouseHouseMetadataFromJSONTyped(json, false);
}

export function UserHouseHouseMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserHouseHouseMetadata {
  if (json == null) {
    return json;
  }
  return {
    firstDateEnergy:
      json['firstDateEnergy'] == null
        ? undefined
        : new Date(json['firstDateEnergy']),
    lastDateEnergy:
      json['lastDateEnergy'] == null
        ? undefined
        : new Date(json['lastDateEnergy']),
    firstDatePower:
      json['firstDatePower'] == null
        ? undefined
        : new Date(json['firstDatePower']),
    lastDatePower:
      json['lastDatePower'] == null
        ? undefined
        : new Date(json['lastDatePower']),
    isOwner: json['isOwner'] == null ? undefined : json['isOwner'],
  };
}

export function UserHouseHouseMetadataToJSON(
  json: any,
): UserHouseHouseMetadata {
  return UserHouseHouseMetadataToJSONTyped(json, false);
}

export function UserHouseHouseMetadataToJSONTyped(
  value?: UserHouseHouseMetadata | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    firstDateEnergy:
      value['firstDateEnergy'] == null
        ? undefined
        : value['firstDateEnergy'].toISOString().substring(0, 10),
    lastDateEnergy:
      value['lastDateEnergy'] == null
        ? undefined
        : value['lastDateEnergy'].toISOString().substring(0, 10),
    firstDatePower:
      value['firstDatePower'] == null
        ? undefined
        : value['firstDatePower'].toISOString().substring(0, 10),
    lastDatePower:
      value['lastDatePower'] == null
        ? undefined
        : value['lastDatePower'].toISOString().substring(0, 10),
    isOwner: value['isOwner'],
  };
}
