import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import ComparisonModuleDraft from 'assets/illustrations/comparison-module-draft.svg?react';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DraftModuleHouseComparison = () => {
  const { tenantId } = useTenantSettings();
  const {
    shouldShowSmartMeterDraftModules,
    shouldSuggestSmartMeterIntegration,
    isSmartMeterIntegrationInProgress,
  } = useSmartMeterIntegration();

  const { t } = useTranslation();

  if (tenantId === 'comunidad-solar' || !shouldShowSmartMeterDraftModules) {
    return null;
  }

  return (
    <ModuleCard>
      <ModuleCard.Header
        title="Comparador de hogares"
        subtitle="Compara tu consumo con el de otros hogares similares"
      />
      <ModuleCard.Content>
        <div className="flex flex-col gap-4">
          <div className="relative">
            <ComparisonModuleDraft />
            {isSmartMeterIntegrationInProgress && (
              <div className="absolute inset-0 flex justify-center items-center text-center px-4 bg-white/70">
                {t('draftModules.waitingConnection')}
              </div>
            )}
          </div>
          {shouldSuggestSmartMeterIntegration && (
            <Link
              to={`/integrations/datadis`}
              className="underline text-clevergy-primary text-right font-semibold"
            >
              Compararme con hogares similares
            </Link>
          )}
        </div>
      </ModuleCard.Content>
    </ModuleCard>
  );
};
