import { Button } from '@clevergy/ui/components/Button';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export type SmartDeviceNotFoundPageProps = {
  deviceId?: string;
  channel?: string;
};

export const SmartDeviceNotFoundPage: FC<SmartDeviceNotFoundPageProps> = ({
  deviceId,
  channel,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedHouse } = useSelectedHouse();

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader title={t('smartDevices.title') as string} />
      <Wrapper>
        <div className="flex flex-col gap-4 justify-center items-center">
          <p className="mb-4 text-sm">
            <Trans
              i18nKey="smartDevices.deviceNotFound.title"
              values={{
                channel: channel || '0',
                deviceId: deviceId || t('smartDevices.deviceNotFound.unknown'),
              }}
            />
          </p>

          <Button
            fullWidth
            onClick={() =>
              navigate(
                `/houses/${selectedHouse?.details.houseId}/smart-devices`,
              )
            }
          >
            {t('smartDevices.deviceNotFound.button')}
          </Button>
        </div>
      </Wrapper>
    </Page>
  );
};
