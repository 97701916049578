import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useOpenIntegrationUrl } from 'hooks/useOpenIntegrationUrl';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { getShellyIntegrationConnectUrl } from 'utils/getShellyIntegrationConnectUrl';

export const useShellyConnect = () => {
  const { selectedHouse } = useSelectedHouse();
  const { openIntegrationUrl } = useOpenIntegrationUrl();
  const queryClient = useQueryClient();

  const openShellyConnect = async () => {
    if (!selectedHouse) {
      return;
    }

    const shellyConnectUrl = getShellyIntegrationConnectUrl(selectedHouse);

    openIntegrationUrl({
      integrationUrl: {
        url: shellyConnectUrl,
        openInAppBrowser: true,
      },
      onFinished: () => {
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeys.GET_SMART_DEVICES,
            { houseId: selectedHouse?.details.houseId },
          ],
        });
      },
    });
  };

  return { openShellyConnect };
};
