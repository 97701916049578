/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { UserHouseDetails } from './UserHouseDetails';
import {
  UserHouseDetailsFromJSON,
  UserHouseDetailsFromJSONTyped,
  UserHouseDetailsToJSON,
  UserHouseDetailsToJSONTyped,
} from './UserHouseDetails';
import type { UserHouseHouseMetadata } from './UserHouseHouseMetadata';
import {
  UserHouseHouseMetadataFromJSON,
  UserHouseHouseMetadataFromJSONTyped,
  UserHouseHouseMetadataToJSON,
  UserHouseHouseMetadataToJSONTyped,
} from './UserHouseHouseMetadata';

/**
 *
 * @export
 * @interface UserHouse
 */
export interface UserHouse {
  /**
   *
   * @type {UserHouseDetails}
   * @memberof UserHouse
   */
  details: UserHouseDetails;
  /**
   *
   * @type {UserHouseHouseMetadata}
   * @memberof UserHouse
   */
  houseMetadata: UserHouseHouseMetadata;
}

/**
 * Check if a given object implements the UserHouse interface.
 */
export function instanceOfUserHouse(value: object): value is UserHouse {
  if (!('details' in value) || value['details'] === undefined) return false;
  if (!('houseMetadata' in value) || value['houseMetadata'] === undefined)
    return false;
  return true;
}

export function UserHouseFromJSON(json: any): UserHouse {
  return UserHouseFromJSONTyped(json, false);
}

export function UserHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserHouse {
  if (json == null) {
    return json;
  }
  return {
    details: UserHouseDetailsFromJSON(json['details']),
    houseMetadata: UserHouseHouseMetadataFromJSON(json['houseMetadata']),
  };
}

export function UserHouseToJSON(json: any): UserHouse {
  return UserHouseToJSONTyped(json, false);
}

export function UserHouseToJSONTyped(
  value?: UserHouse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    details: UserHouseDetailsToJSON(value['details']),
    houseMetadata: UserHouseHouseMetadataToJSON(value['houseMetadata']),
  };
}
