import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { InputSelect } from '@clevergy/ui/components/InputSelect';
import { TenantLogo } from 'components/TenantLogo';
import { useAppSettings } from 'context/AppSettingsContext';
import { useUserHouses } from 'context/UserHousesContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

export type HousePickerProps = {
  flatMode?: boolean;
};

/**
 * NOTE:
 * This component sets the selected house
 * Is not intended to be used outside the UserSetupContext.
 */
export const HousePicker: FC<HousePickerProps> = (props) => {
  const { flatMode = true } = props;

  const { t } = useTranslation();
  const { houses } = useUserHouses();
  const { selectedHouse, setSelectedHouse } = useSelectedHouse();
  const { appSettings } = useAppSettings();

  const handleChangeSelectedHouse = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedHouse(e.target.value);
    e.target.blur();
  };

  const hasHouses = houses && houses.length > 0;
  const isMultiTenant = !appSettings.defaultTenantGcpId;

  if (!hasHouses && !isMultiTenant) {
    return null;
  }

  return (
    <div className="flex w-full gap-2 items-center py-2">
      <div className="min-w-0 grow text-base sm:text-sm">
        {hasHouses && (
          <InputSelect
            hiddenLabel={true}
            id="housesSelector"
            name="housesSelector"
            onChange={handleChangeSelectedHouse}
            values={houses.map((house) => ({
              id: house.details.houseId,
              text: house.details.address || t('housePicker.noAddress'),
            }))}
            flatMode={flatMode}
            label={t('housePicker.label')}
            placeholder={t('housePicker.label')}
            selectedValueId={selectedHouse?.details.houseId}
            icon={
              <>
                <span className="hidden sm:block">
                  <FontIcon name="home" size={16} />
                </span>
                <span className="block sm:hidden">
                  <FontIcon name="home" size={24} />
                </span>
              </>
            }
            fullWidth={isMultiTenant}
          />
        )}
      </div>
      {isMultiTenant && (
        <div className="shrink-0 sm:hidden">
          <TenantLogo type="small" width={32} height={32} />
        </div>
      )}
    </div>
  );
};
