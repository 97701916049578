import { useUserHouses } from 'context/UserHousesContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useEffect } from 'react';
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router';

/**
 * If user reaches a house page without a houseId in the URL and has a selected house, redirect to the selected house page.
 * @returns null
 */
export const HousePagesRedirect: FC = () => {
  const { houses } = useUserHouses();
  const { selectedHouse } = useSelectedHouse();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const firstHouse = houses?.[0];
    const matchesWithoutHouseIdParam = matchRoutes(
      [
        { path: '/' },
        { path: '/analysis' },
        { path: '/optimize' },
        { path: '/alerts' },
        { path: '/invoices' },
        { path: '/settings' },
        { path: '/smart-devices' },
      ],
      location,
    );
    if (matchesWithoutHouseIdParam) {
      if (selectedHouse) {
        const newUrl = `/houses/${selectedHouse.details.houseId}${matchesWithoutHouseIdParam[0].pathname}`;
        navigate(newUrl, { replace: true });
      }

      if (selectedHouse === null && firstHouse) {
        const newUrl = `/houses/${firstHouse.details.houseId}${matchesWithoutHouseIdParam[0].pathname}`;
        navigate(newUrl, { replace: true });
      }
    }
  }, [selectedHouse, navigate, location, houses]);

  return <Outlet />;
};
