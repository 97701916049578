export const QueryKeys = {
  CONSUMPTION_DETAILS: 'consumptionDetails',
  CONSUMPTION_RANGE: 'consumptionRange',
  FIND_HOUSE_ENERGY: 'findHouseEnergy',
  FIND_HOUSE_SUPPLIES_ENERGY: 'findHouseSuppliesEnergy',
  GET_APP_INFO: 'getAppInfo',
  GET_AUTHORIZED_USERS: 'getAuthorizedUsers',
  GET_BATTERY_REAL_TIME: 'getBatteryRealTime',
  GET_CONSUMPTION_CUMULATIVE: 'getConsumptionCumulative',
  GET_ENERGY_PRICES_AVG: 'getEnergyPricesAvg',
  GET_ENERGY_PRICES_CONSUMPTIONS: 'getEnergyPricesConsumptions',
  GET_ENERGY_PRICES_MAX: 'getEnergyPricesMax',
  GET_ENERGY_PRICES_MIN: 'getEnergyPricesMin',
  GET_ENERGY_PRICES_SURPLUS: 'getEnergyPricesSurplus',
  GET_USER_HOUSES: 'getUserHouses',
  GET_USER_HOUSE: 'getUserHouse',
  GET_HOUSE_DEVICES: 'getHouseDevices',
  GET_HOUSE_INVOICE: 'getHouseInvoice',
  GET_HOUSE_LAST_INVOICE: 'getHouseLastInvoice',
  GET_HOUSE_SETTINGS: 'getHouseSettings',
  GET_INTEGRATION_OAUTH_LOGIN_URL: 'getIntegrationOauthLoginUrl',
  GET_INVOICES_FOR_HOUSE: 'getInvoicesForHouse',
  GET_INVOICES_PAGE_FILTER: 'getInvoicesPageFilter',
  GET_INVOICES_PAGE_FOR_HOUSE: 'getInvoicesPageForHouse',
  GET_OPTIMIZE_RECOMMENDATIONS: 'getOptimizeRecommendations',
  GET_OPEN_CONTRACT_LEAD: 'getOpenContractLead',
  GET_POWER: 'getPower',
  GET_REAL_TIME_POWER: 'getRealTimePower',
  GET_RULE_PARAMS: 'getRuleParams',
  GET_SMART_DEVICE: 'getSmartDevice',
  GET_SMART_DEVICES: 'getSmartDevices',
  GET_SUPPORT_CUSTOM_ISSUES_LIST: 'getSupportCustomIssuesList',
  GET_TENANT_SETTINGS: 'getTenantSettings',
  GET_USER_TARIFFS: 'getUserTariffs',
  GET_TICKET: 'getTicket',
  GET_TICKETS: 'getTickets',
  GET_TIPS: 'getTips',
  GET_USER_ACCOUNT_DETAILS: 'getUserAccountDetails',
  GET_USER_PREFERENCES_NOTIFICATIONS: 'getUserPreferencesNotifications',
  GET_USER_SETUP: 'getUserSetup',
  GET_USER_INTEGRATIONS: 'getUserIntegrations',
  GET_VIRTUAL_BATTERY: 'getVirtualBattery',
  GET_VIRTUAL_WALLET: 'getVirtualWallet',
  HOUSE_COMPARISON: 'houseComparison',
  HOUSE_CONTRACT: 'houseContract',
  RECOMMENDED_RATE: 'recommendedRate',
  REGISTERED_HOUSE_DEVICES: 'registeredHouseDevices',
  SOLAR_INFO: 'solarInfo',
  SOLAR_RECOMMENDATION_CHART: 'solarRecommendationChart',
  SOLAR_RECOMMENDATION: 'solarRecommendation',
  UPDATE_USER_ACCOUNT_DETAILS: 'updateUserAccountDetails',
} as const;
